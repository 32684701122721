<div class="chatroom-form-container">
    <form class="converse-form chatroom-form converse-centered-form">
        <fieldset class="form-group">
            <label>{{{o.heading}}}</label>
            <p class="validation-message">{{{o.validation_message}}}</p>
            <input type="text" required="required" name="nick" class="form-control" placeholder="{{{o.label_nickname}}}"/>
        </fieldset>
        <input type="submit" class="btn btn-primary" name="join" value="{{{o.label_join}}}"/>
    </form>
</div>
