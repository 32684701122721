<!-- Add contact Modal -->
<div class="modal fade" id="add-contact-modal" tabindex="-1" role="dialog" aria-labelledby="addContactModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addContactModalLabel">{{{o.heading_new_contact}}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <form class="converse-form add-xmpp-contact">
                <div class="modal-body">
                    <div class="form-group add-xmpp-contact__jid">
                        <label class="clearfix" for="jid">{{{o.label_xmpp_address}}}:</label>
                        <div class="suggestion-box suggestion-box__jid">
                            <ul class="suggestion-box__results suggestion-box__results--above" hidden=""></ul>
                            <input type="text" name="jid"
                                   {[ if (!o._converse.xhr_user_search_url) { ]} required="required" {[ } ]}
                                   value="{{{o.jid}}}"
                                   class="form-control suggestion-box__input"
                                   placeholder="{{{o.contact_placeholder}}}"/>
                            <span class="suggestion-box__additions visually-hidden" role="status" aria-live="assertive" aria-relevant="additions"></span>
                        </div>
                    </div>
                    <div class="form-group add-xmpp-contact__name">
                        <label class="clearfix" for="name">{{{o.label_nickname}}}:</label>
                        <div class="suggestion-box suggestion-box__name">
                            <ul class="suggestion-box__results suggestion-box__results--above" hidden=""></ul>
                            <input type="text" name="name" value="{{{o.nickname}}}"
                                   class="form-control suggestion-box__input"
                                   placeholder="{{{o.nickname_placeholder}}}"/>
                            <span class="suggestion-box__additions visually-hidden" role="status" aria-live="assertive" aria-relevant="additions"></span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="invalid-feedback">{{{o.error_message}}}</div>
                    </div>
                    <button type="submit" class="btn btn-primary">{{{o.label_add}}}</button>
                </div>
            </form>
        </div>
    </div>
</div>
