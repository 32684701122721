<div class="chatroom-form-container">
    <form class="pure-form converse-form chatroom-form">
        <fieldset class="form-group">
            <legend>{{{o.heading}}}</legend>
            <label>{{{o.label_password}}}</label>
            <input type="password" name="password"/>
        </fieldset>
        <input class="btn btn-primary" type="submit" value="{{{o.label_submit}}}"/>
    </form>
</div>
