<div class="suggestion-box room-invite">
    <form>
        {[ if (o.error_message) { ]} <span class="error">{{{o.error_message}}}</span> {[ } ]}
        <div class="form-group">
            <input class="form-control invited-contact suggestion-box__input"
                   placeholder="{{{o.label_invitation}}}"
                   type="text"/>
            <span class="suggestion-box__additions visually-hidden" role="status" aria-live="assertive" aria-relevant="additions"></span>
        </div>
    </form>
    <ul class="suggestion-box__results suggestion-box__results--below" hidden=""></ul>
</div>
