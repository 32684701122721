<div class="modal fade" id="user-details-modal" tabindex="-1" role="dialog" aria-labelledby="user-details-modal-label" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="user-details-modal-label">{{{o.display_name}}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="{{{o.__('Close')}}}"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                {[ if (o.image) { ]}
                <img alt="{{{o.__('The User\'s Profile Image')}}}"
                    class="img-thumbnail avatar align-self-center mb-3"
                    height="100" width="100" src="data:{{{o.image_type}}};base64,{{{o.image}}}"/>
                {[ } ]}
                {[ if (o.fullname) { ]}
                <p><label>{{{o.__('Full Name:')}}}</label> {{{o.fullname}}}</p>
                {[ } ]}
                <p><label>{{{o.__('XMPP Address:')}}}</label> <a href="xmpp:{{{o.jid}}}">{{{o.jid}}}</a></p>
                {[ if (o.nickname) { ]}
                <p><label>{{{o.__('Nickname:')}}}</label> {{{o.nickname}}}</p>
                {[ } ]}
                {[ if (o.url) { ]}
                <p><label>{{{o.__('URL:')}}}</label> <a target="_blank" rel="noopener" href="{{{o.url}}}">{{{o.url}}}</a></p>
                {[ } ]}
                {[ if (o.email) { ]}
                <p><label>{{{o.__('Email:')}}}</label> <a href="mailto:{{{o.email}}}">{{{o.email}}}</a></p>
                {[ } ]}
                {[ if (o.role) { ]}
                <p><label>{{{o.__('Role:')}}}</label> {{{o.role}}}</p>
                {[ } ]}

                {[ if (o._converse.pluggable.plugins['converse-omemo'].enabled(o._converse)) { ]}
                    <hr/>
                    <ul class="list-group fingerprints">
                        <li class="list-group-item active">{{{o.__('OMEMO Fingerprints')}}}</li>
                        {[ if (!o.view.devicelist.devices) { ]}
                            <li class="list-group-item"><span class="spinner fa fa-spinner centered"/></li>
                        {[ } ]}
                        {[ if (o.view.devicelist.devices) { ]}
                            {[ o.view.devicelist.devices.each(function (device) { ]}
                                {[ if (device.get('bundle') && device.get('bundle').fingerprint) { ]}
                                <li class="list-group-item">
                                    <form class="fingerprint-trust">
                                    <div class="btn-group btn-group-toggle">
                                        <label class="btn btn--small {[ if (device.get('trusted') !== -1) { ]} btn-primary active {[ } else { ]}  btn-secondary {[ } ]}">
                                            <input type="radio" name="{{{device.get('id')}}}" value="1"
                                                {[ if (device.get('trusted') !== -1) { ]} checked="checked" {[ } ]}/>{{{o.__('Trusted')}}}
                                        </label>
                                        <label class="btn btn--small {[ if (device.get('trusted') === -1) { ]} btn-primary active {[ } else { ]} btn-secondary {[ } ]}">
                                            <input type="radio" name="{{{device.get('id')}}}" value="-1"
                                                {[ if (device.get('trusted') === -1) { ]} checked="checked" {[ } ]}/>{{{o.__('Untrusted')}}}
                                        </label>
                                    </div>
                                    <span class="fingerprint">{{{o.utils.formatFingerprint(device.get('bundle').fingerprint)}}}</span>
                                    </form>
                                </li>
                                {[ } ]}
                            {[ }); ]}
                        {[ } ]}
                    </ul>
                {[ } ]}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning" data-dismiss="modal">{{{o.__('Close')}}}</button>
                <button type="button" class="btn btn-info refresh-contact"><i class="fa fa-refresh"> </i>{{{o.__('Refresh')}}}</button>
                {[ if (o.allow_contact_removal && o.is_roster_contact) { ]}
                    <button type="button" class="btn btn-danger remove-contact"><i class="far fa-trash-alt"> </i>{{{o.__('Remove as contact')}}}</button>
                {[ } ]}
            </div>
        </div>
    </div>
</div>
